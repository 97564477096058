
@media (max-width: 767px) {

}
/** {*/
/*    font-family: Roboto;*/
/*    scroll-behavior: smooth;*/

/*}*/


@media (min-width:961px)  {
    h1{
        font-size: 2.5rem;
    }
    h2 {
        font-size: 1.7rem;
        color: #1d2d3f;
    }
    a,p {
        color: #1d2d3f;
        font-size: 0.6rem;
    }
    h3 {
        font-size: 1.1rem;
        color: #1d2d3f;
    }
    h4{
        font-size: 0.8rem
    }
}
@media (min-width:1281px) {
    h1{
        font-size: 3.3rem;
    }
    h2 {
        font-size: 2.4rem;
        color: #1d2d3f;
    }
    a,p {
        color: #1d2d3f;
        font-size: 0.8rem;
    }
    h3 {
        font-size: 1.4rem;
        color: #1d2d3f;
    }
    h4{
        font-size: 1.1rem
    }
}
@media (min-width:1921px) {
    h1{
        font-size: 5rem;
    }
    h2 {
        font-size: 3.84rem;
        color: #1d2d3f;
    }
    a,p {
        color: #1d2d3f;
        font-size: 1.2rem;
    }
    h3 {
        font-size: 2.16rem;
        color: #1d2d3f;
    }
    h4{
    font-size: 1.625rem
    }
}
